@font-face {
    font-family: 'Raleway';
    src: url('Raleway-BlackItalic.eot');
    src: url('Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-BlackItalic.woff2') format('woff2'),
        url('Raleway-BlackItalic.woff') format('woff'),
        url('Raleway-BlackItalic.ttf') format('truetype'),
        url('Raleway-BlackItalic.svg#Raleway-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Black.eot');
    src: url('Raleway-Black.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Black.woff2') format('woff2'),
        url('Raleway-Black.woff') format('woff'),
        url('Raleway-Black.ttf') format('truetype'),
        url('Raleway-Black.svg#Raleway-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Light.eot');
    src: url('Raleway-Light.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Light.woff2') format('woff2'),
        url('Raleway-Light.woff') format('woff'),
        url('Raleway-Light.ttf') format('truetype'),
        url('Raleway-Light.svg#Raleway-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraLight.eot');
    src: url('Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ExtraLight.woff2') format('woff2'),
        url('Raleway-ExtraLight.woff') format('woff'),
        url('Raleway-ExtraLight.ttf') format('truetype'),
        url('Raleway-ExtraLight.svg#Raleway-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Italic.eot');
    src: url('Raleway-Italic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Italic.woff2') format('woff2'),
        url('Raleway-Italic.woff') format('woff'),
        url('Raleway-Italic.ttf') format('truetype'),
        url('Raleway-Italic.svg#Raleway-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBold.eot');
    src: url('Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ExtraBold.woff2') format('woff2'),
        url('Raleway-ExtraBold.woff') format('woff'),
        url('Raleway-ExtraBold.ttf') format('truetype'),
        url('Raleway-ExtraBold.svg#Raleway-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraLightItalic.eot');
    src: url('Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ExtraLightItalic.woff2') format('woff2'),
        url('Raleway-ExtraLightItalic.woff') format('woff'),
        url('Raleway-ExtraLightItalic.ttf') format('truetype'),
        url('Raleway-ExtraLightItalic.svg#Raleway-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Bold.eot');
    src: url('Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Bold.woff2') format('woff2'),
        url('Raleway-Bold.woff') format('woff'),
        url('Raleway-Bold.ttf') format('truetype'),
        url('Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBoldItalic.eot');
    src: url('Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ExtraBoldItalic.woff2') format('woff2'),
        url('Raleway-ExtraBoldItalic.woff') format('woff'),
        url('Raleway-ExtraBoldItalic.ttf') format('truetype'),
        url('Raleway-ExtraBoldItalic.svg#Raleway-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-BoldItalic.eot');
    src: url('Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-BoldItalic.woff2') format('woff2'),
        url('Raleway-BoldItalic.woff') format('woff'),
        url('Raleway-BoldItalic.ttf') format('truetype'),
        url('Raleway-BoldItalic.svg#Raleway-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Regular.eot');
    src: url('Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Regular.woff2') format('woff2'),
        url('Raleway-Regular.woff') format('woff'),
        url('Raleway-Regular.ttf') format('truetype'),
        url('Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-MediumItalic.eot');
    src: url('Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-MediumItalic.woff2') format('woff2'),
        url('Raleway-MediumItalic.woff') format('woff'),
        url('Raleway-MediumItalic.ttf') format('truetype'),
        url('Raleway-MediumItalic.svg#Raleway-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBoldItalic.eot');
    src: url('Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-SemiBoldItalic.woff2') format('woff2'),
        url('Raleway-SemiBoldItalic.woff') format('woff'),
        url('Raleway-SemiBoldItalic.ttf') format('truetype'),
        url('Raleway-SemiBoldItalic.svg#Raleway-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Medium.eot');
    src: url('Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Medium.woff2') format('woff2'),
        url('Raleway-Medium.woff') format('woff'),
        url('Raleway-Medium.ttf') format('truetype'),
        url('Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBold.eot');
    src: url('Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Raleway-SemiBold.woff2') format('woff2'),
        url('Raleway-SemiBold.woff') format('woff'),
        url('Raleway-SemiBold.ttf') format('truetype'),
        url('Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-LightItalic.eot');
    src: url('Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-LightItalic.woff2') format('woff2'),
        url('Raleway-LightItalic.woff') format('woff'),
        url('Raleway-LightItalic.ttf') format('truetype'),
        url('Raleway-LightItalic.svg#Raleway-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ThinItalic.eot');
    src: url('Raleway-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Raleway-ThinItalic.woff2') format('woff2'),
        url('Raleway-ThinItalic.woff') format('woff'),
        url('Raleway-ThinItalic.ttf') format('truetype'),
        url('Raleway-ThinItalic.svg#Raleway-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Thin.eot');
    src: url('Raleway-Thin.eot?#iefix') format('embedded-opentype'),
        url('Raleway-Thin.woff2') format('woff2'),
        url('Raleway-Thin.woff') format('woff'),
        url('Raleway-Thin.ttf') format('truetype'),
        url('Raleway-Thin.svg#Raleway-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

