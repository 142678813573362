$main_blue: #0e65b1;
$main_white: #fff;
$main_green: green;
$text-grey: #5e6672;
$input-border-grey: #5e6672;
$main-red: #ea2148;
$main-red_hover: #b91d3b;
$main-text-color: #3e405a;
$main-text-color-darker: #c7c7c7;
$sidebar-menu-color: #3e405a;
$lk-title: #3e405a;
$main-grey: #585a7a;
$exam-date-title: #0e60ab;
$text-grey-light: #747793;
$background-light-grey: #eeeef0;
$text-blue: #0f6cb6;
$text-pale-status: #898dbc;
$light-grey: #d2d3da;
$light-grey2: #e2e2e8;
$light-blue: #cce3f1;
$tab-grey: #e5e5e7;
$tab-grey-border: #c2c3cb;
$hierarchy-grey: #f6f6f7;
$hierarchy-border-grey: #d7d8e4;
$actions-dots: #757b95;
$checkbox-grey: #75767f;
$disabled-button: #c3c4c6;
$disabled-button_darker: #9ea2ab;
$question-navigation-border: #8a8ca5;
$question-option-border: #a9aabb;
$timer-background: #d4d5db;
$exam-result-red: #e20d3c;
$exam-result-blue: #1065af;
$exam-result-blue_darker: #0b497f;
$link_color: #0988f7;
$sidebar_red: #d21e41;