@import "_reset.scss";
@import '_colors.scss';
@import '_fonts.scss';
@import '_buttons.scss';
@import '_tabs.scss';
@import 'layout.scss'; 
@import 'titles';
@import 'sidebar.scss';
@import 'application_create';
@import '_schedule-calendar.scss';
@import '_form-elements.scss';
@import 'items-list';
@import 'choices.scss';
@import 'flatpickr.scss'; 
@import 'exam';
@import 'simplebar/dist/simplebar.css';
@import 'cropperjs/dist/cropper.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'dragula/dist/dragula.min.css';
@import 'utility';
@import 'mixins';
@import 'spinner';
@import '_modal.scss';
@import 'tables';
@import 'treeview';
@import 'hierarchy_list';
@import 'breakpoints';
@import 'fullcalendar';

html, body {
min-height: 100vh;
}
body {
    font-size: 14px;
    font-family: Raleway, sans-serif;
    position: relative;
    min-width: 1280px;
    display: flex;
}

a {
    color: $link_color;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

p {
    margin-bottom: 15px;
    line-height: 1.2;
}

.main-background {
    background-image: url('../images/background_main.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    &.login-page {
        background-image: url('../images/background_login.png'); 
    }
}

* {
    box-sizing: border-box;
}

.d-none {
    display: none !important;
}

.disabled {
    pointer-events: visiblestroke;
    opacity: 0.5;
}

.middle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

input:disabled,
textarea:disabled,
select:disabled,
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    --color: $light_grey;
}

textarea[name="ResonReject"] {
    min-height: 120px !important;
}


.header-notifications {
    display: block;
    width: 32px;
    height: 31px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='31px' viewBox='0 0 32 31' style='enable-background:new 0 0 32 31;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%233E405A;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='32' height='31'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M15.8,27.8c-0.6,0-1.2,0-1.8-0.1L6.2,27l-4.6,0c-0.5,0-0.9-0.2-1.2-0.5C0.1,26.2,0,25.8,0,25.3 c0-0.9,0.8-1.6,1.7-1.6h0.9c1.8,0,3.3-1.5,3.3-3.3v-8.6c0-5.5,4.4-9.9,9.9-9.9s9.9,4.4,9.9,9.9v8.6c0,1.8,1.5,3.3,3.3,3.3h1.4 c0.8,0,1.6,0.6,1.7,1.4c0.1,0.5,0,1-0.4,1.4c-0.3,0.4-0.8,0.6-1.3,0.6h-5.1l-7.7,0.7C16.9,27.8,16.3,27.8,15.8,27.8 M1.7,24.6 c-0.5,0-0.9,0.3-0.9,0.8c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3h4.6l7.8,0.7c1.1,0.1,2.2,0.1,3.4,0l7.8-0.7l5.1,0 c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.2-0.4,0.2-0.7c-0.1-0.4-0.4-0.7-0.9-0.7h-1.4c-2.3,0-4.1-1.8-4.1-4.1v-8.6c0-5-4.1-9-9-9 c-5,0-9,4.1-9,9v8.6c0,2.3-1.8,4.1-4.1,4.1H1.7z'/%3E%3Cpath class='st0' d='M15.8,31c-1.9,0-3.5-1.6-3.5-3.5c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,1.5,1.2,2.6,2.6,2.6 c1.5,0,2.6-1.2,2.6-2.6c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4C19.3,29.4,17.7,31,15.8,31'/%3E%3Cpath class='st0' d='M15.8,6.2c-0.2,0-0.4-0.2-0.4-0.4V0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v5.3 C16.2,6,16,6.2,15.8,6.2'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 32px 31px;
    margin-right: 64px;
    position: relative;

    .notifications-counter {
        display: flex;
        font-family: Raleway, sans-serif;
        font-weight: bold;
        font-size: 14px;
        justify-content: center;
        padding-top: 2px;
        position: absolute;
        top: -5px;
        right: -10px;
        background-color: $main-red;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        color: #fff;
    }
}
.header-username {
    font-family: Raleway, sans-serif;
    text-decoration: none;
    font-size: 14px;
    color: $lk-title;
    margin-right: 20px;
    &:hover {
        text-decoration: underline;
    }
}

.header-logout {
    display: block;
    width: 25px;
    height: 26px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='25px' height='26px' viewBox='0 0 25 26' style='enable-background:new 0 0 25 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:none;stroke:%233E405A;stroke-width:1.0446;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='25' height='26'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpolygon class='st0' points='18.4,18.2 24.5,13 18.4,7.8 18.4,10.3 7.1,10.3 7.1,15.7 18.4,15.7 '/%3E%3Cpolyline class='st0' points='16.1,17.6 16.1,25.4 0.5,25.4 0.5,0.5 16.1,0.5 16.1,7.9 '/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    backgrond-position: center;
    background-size: 25px;
}

.site-content {
    margin-top: 72px;
    margin-bottom: 50px;
}

.trial-test-link {
    display: flex;
    width: 196px;
    height: 155px;
    background-color: $main_blue;
    background-image: url('../images/icon-testing.svg');
    background-repeat: no-repeat;
    background-position: top 40px center;

    &:hover {
        .trial-test-link-text {
            text-decoration: underline;
        }
    }

    .trial-test-link-text {
        line-height: 1.222;
        color: $main_white;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-top: auto;
        padding: 15px;
        font-size: 16px;
    }
}



.login-form {
    width: 391px;
    border-radius: 10px;
    background-color: rgba(246, 246, 246, 0.91);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.17);
    padding: 20px;
    padding-top: 25px;
    padding-bottom: 44px;

    &.set-password-form {
        width: auto;
        margin-top: 60px;

        .tab-content {
            background: transparent;
        }
    }

    .form-group {
        margin-bottom: 30px;
        position: relative;

        .pristine-error {
            color: $main-red;
            position: absolute;
            bottom: -18px;
            font-size: 12px;
        }

        &.form-group-horizontal {
            display: flex;

            label {
                display: flex;
                align-items: flex-start;
            }
        }
    }

    .form-label {
        font-size: 14px;
        color: $text-grey;

        .input-control {
            font-size: 18px;
            margin-top: 10px;
        }
    }

    .input-control {
        width: 100%;
        border: 1px solid $input-border-grey;
        border-radius: 5px;
        background-color: transparent;
        height: 50px;
        padding: 15px;
        color: $text-grey;
    }

    .password-reminder {
        font-size: 14px;
        color: $text-grey;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $text-grey;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    .login-form-button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .btn {
            width: 166px;
        }
    }
}

.label-text {
    &.label-text-link {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &#view_agreement {
            text-decoration: underline;
        }
    }

    &.required {
        &:after {
            content: '*';
            color: $main-red;
            font-weight: bold;
            margin-left: -3px;
        }
    }
}

.simplebar-scrollbar::before {
    background-color: $main_blue;
}
.simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
}

.simplebar-contenr {
    padding-right: 15px;
}
.simplebar-track {
    /*right: -15px;*/
}

.pagination-info {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $main-text-color;
}

input:-webkit-autofill {
    font-size: 16px !important;
    color: $text-grey !important;
}

.initial-data-container {
    padding: 10px;
    padding-right: 15px;
    border-radius: 5px;
    border: 1px solid #5e6672;
    margin-bottom: 10px;
    position: relative; 
}

.pointer{
    cursor: pointer;
}

.w60{
    width:60px;
}


