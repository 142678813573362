.tab-content {
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
    margin-bottom: 30px;
}

.exam-options-container {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .exam-options-item {
        font-size: 12px;
        font-family: Raleway, sans-serif;
        color: $checkbox-grey;
        width: 300px;
        margin-bottom: 20px;
    }
    .exam-options-item_r {
        font-size: 12px;
        font-family: Raleway, sans-serif;
        color: $checkbox-grey;
        width: 100px;
        margin-bottom: 20px;
    }
}



.application-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;

    .application-status-title {
        font-family: Raleway, sans-serif;
        color: $checkbox-grey;
        margin-right: 10px;
    }

    .application-status-text {
        display: flex;
        height: 20px;
        min-width: 100px;
        padding: 5px;
        background-color: $main-red;
        justify-content: center;
        color: #fff;
    }
}

.application-additional-info {
    display: flex;
    font-weight: 400;

    .application-additional-info-item {
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
}

.application-status-bar {
    font-size: 12px;
    font-family: Raleway, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    .application-status {
        margin-right: 80px;
    }
}

.application-buttons-container {
    display: flex;
    justify-content: flex-end;

    .btn:not(:last-child) {
        margin-right: 15px;
    }

    .btn[disabled] {

        &[disabled] {
            background-color: $disabled-button;
            color: #fff;
            cursor: not-allowed;
            pointer-events: none;

            opacity: 1;
        }
    }
}

.documents-buttons {
    margin-bottom: 45px;
}

.document-list-table {
    min-width: 700px;
    border-collapse: separate;
    border-spacing: 0 10px;

    td {
        padding-right: 30px;
        vertical-align: middle;
    }

    .item-menu-container {
        font-size: 20px;
        font-weight: bold;
        color: $main-text-color;
    }

    .document-table-item {
        display: flex;
        align-items: center;

        &.archive {
            opacity: 0.5;
        }
    }

    .document-name,
    .document-file-name {
        font-family: Raleway, sans-serif;
        font-weight: 400;
        font-size: 16px;
    }

    .document-file-name {
        color: $main_blue;
    }

    .item-menu-title {
        font-size: 12px;
        font-weight: 400;
        font-family: Raleway, sans-serif;
        margin-right: 5px;
    }
}

.personal-info-tabs {
    .tab-item {
        width: 263px;
    }
}

.personal-photo-col {
    width: 263px;
    flex-grow: 0;
    flex-shrink: 0;
}

#personal_information_form {
    .form-group .pristine-error {
        bottom: -13px;
        font-size: 11px;
    }
}

.personal-photo-container {
    overflow: hidden;
    height: 231px;
    background-color: $sidebar-menu-color;
    border-radius: 10px;
    position: relative;

    img {
        position: relative;
        width: 100%;
    }

    &:before {
        content: 'Фото';
        position: absolute;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        width: 100%;
        font-family: Raleway, sans-serif;
        font-size: 18px;
        color: #fff;
        height: 100%;
    }
}
.personal-photo-col {
    margin-right: 30px;
    .photo-actions {
        display: flex;
        padding: 0 5px;
        padding-top: 20px;
        justify-content: space-between;

        .photo-action-link {
            color: $main-text-color;
        }

        .photo-action-add {
            .photo-action-link {
                &:hover {
                    text-decoration: none;
                    border-bottom: none;
                }

                border-bottom: 1px dotted $main-text-color;
            }
        }
    }
}

.list-item .list-item-link-sq {
    position: absolute;
    top: 0; bottom: 0;
    width: 100%;
}


.item-pending
{
    background-color: $timer-background;
}

.question-count-info {
    display: inline-flex;
    margin-bottom: 25px;

    .background-container {
        display: flex;
    }

    .list-item-name {
        margin-right: 30px;
    }

    .accent {
        color: #000;
    }
}

.modal-question-count-info {
    display: flex;
    margin-bottom: 10px;

    .list-item-name {
        margin-right: 40px;
    }

    .accent {
        color: #000;
    }
}

.mr-20p{
    width:20% !important;
}

.mr-30p{
    width:30% !important;
}

.mr-25p{
    width:25% !important;
}