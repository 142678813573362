﻿.font-12 {
    font-size: 12px !important;
}
.font-14 {
    font-size: 14px !important;
}
.font-16 {
    font-size: 16px !important;
}
.font-18 {
    font-size: 18px;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-grow-choices {
    .choices {
        flex-grow: 1;
    }
}

.line-height-20 {
    line-height: 20px;
}

.row-wrap {
    flex-wrap: wrap;
}
.col {
    flex-grow: 1;
    /*flex-basis: 0;*/
    min-width: 0;
    display: flex;
    flex-direction: column;
}

.col-content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto; 
}
.col-50 {
    //width: 50%;
    flex-grow: 1;
    min-width: 0; /*hack to prevent overflowing parent*/
    display: flex;
    flex-direction: column;
}
.col-4 {
    width: 25%;
    min-width: 0;
    display: flex;
    flex-direction: column;
}

.b {
    font-weight: bold;
}

.col-100px {
    flex-grow: 0;
    width: 100px;
    flex-basis: auto;
    flex-shrink: 0;
}

.col-260px {
    flex-grow: 0;
    width: 260px;
    flex-basis: auto;
	flex-shrink: 0;
}

.col-360px {
    flex-grow: 0;
    flex-shrink: 0;
    width: 360px !important;
    flex-basis: auto;
}

.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.mr-15 {
    margin-right: 15px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}


.pt-90 {
    padding-top: 90px !important;
}

.pt-5 {
    padding-top: 5px !important;
}
.p-10 {
    padding: 10px !important;
}
.p-0 {
    padding: 0 !important;
}
.pr-15 {
    padding-right: 15px !important;
}

.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}


.status-red {
    color: $main-red!important;
}

.status-blue {
    color: $main_blue!important;
}

.flex-end {
    justify-content: flex-end;
}

.italic {
    font-style:italic;
}

.bold {
    font-weight: bold;
}

.pr-30 {
    padding-right: 30px;
}

.pr-60 {
    padding-right: 60px;
}

.px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.pr-70 {
    padding-right: 70px;
}

.mr-30 {
    margin-right: 30px !important;
}
.mr-60 {
    margin-right: 60px !important;
}

.pr-100 {
    padding-right: 100px;
}

.no-margin-collapsing {
    padding: 0.05px;
}

.width-160 {
    width: 160px;
    flex-grow: 0;
    flex-shrink: 0;
}

.width-180 {
    width: 160px;
    flex-grow: 0;
    flex-shrink: 0;
}


.width-200 {
    width: 200px;
    min-width: auto !important;
    flex-grow: 0;
    flex-shrink: 0;
}
.width-260 {
    width: 260px;
    flex-grow: 0;
    flex-shrink: 0;
}

.width-300 {
    width: 300px;
    flex-grow: 0;
	flex-shrink: 0;
}

.width-450 {
    width: 450px;
    flex-grow: 0;
    flex-shrink: 0;
}

.space-between {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.background-grey {
    background-color: #f6f6f6;
}
.center {
    text-align: center !important;
}

.align-center {
    display: flex;
    align-items: center !important;
}

.align-end {
    display: flex;
    align-items: flex-end !important;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.mr-30 {
    margin-right: 30px !important;
}

.ml-10 {
    margin-left: 10px !important;
}
.mt-0 {
    margin-top: 0 !important;
}

.mt-5 {
    margin-top: 5px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.pb-15 {
    padding-bottom: 15px;
}
.text-blue {
    color:#1065af;
}
.text-blue-pale {
    color: $text-pale-status;
}

.text-red {
    color: #d21e41;
}

.text-neutral {
    color: #3e405a;
}

.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}

.text-green {
    color: #08a42c;
}
.width-100 {
    width: 100px;
}

.width-100-percent {
    width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.alert-message {
    color: $main-red;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 30px;
}

.font-weight-normal {
    font-weight: normal !important;
}

.list-dash {
    list-style-type: none;
    &>li {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:before {
            content: '-';
        }
    }
}

.numbered-list {
    margin-left: 15px;
    list-style: decimal;
    &>li {
        margin-bottom: 10px;
    }
}

.display-none {
    display: none !important;
}

.to-right {
    margin-left: auto;
}

.external-link {
    color: #0988f7;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.linktext {
    color: #0988f7;
    text-decoration: underline;
    cursor: pointer;
}

.text_underline {
    text-decoration: underline;
}