﻿
@mixin border-radius-1 {
    border-radius: 5px;
}
@mixin border-1 {
    border: 1px solid $input-border-grey;
}
@mixin border-type-1 {
    @include border-radius-1;
    @include border-1;
}

@mixin box-shadow {
    box-shadow: 0px 3px 3px 0px rgba(133, 138, 148, 0.39);
}

@mixin box-shadow-right {
    box-shadow: 2.516px 1.634px 6px 0px rgba(0, 0, 0, 0.23);
}

@function px2percent($px, $container: 1170px) {
    @return $px * 100% / $container
}
