@media (max-width: 1500px) {
    .wrapper {
        width: 900px;
    }
    .block-links-container .block-link {
        width: 170px;
    }
    .card-with-pin {
        .bold {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    .line-tabs .tab-item .tab-item-link {
        color: inherit;
        font-size: 14px;
    }

    .items-list-container .list-item .list-item-header .additional-info-items .additional-info-item {
        padding: 0 15px;
    }

    .list-item-name.width-200 {
        width: 102px;
    }

}

