.schedule-calendar-container {
    display: flex;  
    flex-direction: column;

    .filters-container, .filters-container form {
        display: flex;
        width: 100%;

        margin-right: 15px;
        .big-checkbox {
            margin-top: 28px;
            margin-left: 3px;
        }


        flex-shrink: 0;
    }

    .calendar-container {
        flex-grow: 1;
    }
}

.col-calendar {
    max-width: 555px;
    margin-left: auto;
}

.schedule-calendar-container {
    .form-group {
        &:not(:last-child) {
            padding-right: 8px;
        }
        width: 33.33%;

        .choices {
            width: 100%;
        }
    }
}