.sidebar {
    transition: 0.2s width ease-in-out;
    overflow-x: hidden;
    padding-top: 10px;
    position: fixed;
    overflow-y: auto;
    background-color: #3e405a;
    height: 100vh;
    top: 0;
    bottom: 0;
    width: 56px;
    display: flex;
    flex-direction: column;
    z-index: 3003;

    &:hover {
        width: 265px;

        .sidebar-menu-item {
            .sidebar-menu-item-link-text {
                opacity: 1;
            }
        }
    }

    .user {
        border-bottom: 1px solid #707288;
        width: 100%;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-img {
        border-radius: 50%;
    }

    .sidebar-menu {
        flex-grow: 1;
        padding-top: 25px;
        position: relative;

        &:hover {
            .sidebar-menu-item {
                .sidebar-menu-item-link-text {
                    //transition: left 0.2s ease-in, opacity 0.2s ease-in;
                    //left: calc(100%);
                    //padding-left: 15px;
                    //opacity: 1;
                }
            }
        }
    }

    .sidebar-menu-item {
        .sidebar-menu-item-link-text {
            transition: 0.2s opacity ease-in-out;
            opacity: 0;
        }

        font-weight: 200;
        font-size: 14px;
        line-height: 18px;
        font-family: Raleway, sans-serif;
        padding: 10px 0 10px 18px;
        margin-bottom: 10px;
        min-height: 21px;
        width: 265px;

        &.active {
            .sidebar-menu-item-link {
                //&:before {
                //    content: '';
                //    display: block;
                //    position: absolute;
                //    top: 7px;
                //    bottom: 7px;
                //    left: 7px;
                //    right: 7px;
                //    background-color: $sidebar_red;
                //    border-radius: 50%;
                //}
            }
        }

        &:hover, &.active {
            background-color: #171936;
        }

        .sidebar-menu-item-link-icon {
            width: 21px;
            display: flex;
            flex-shrink: 0;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-right: 16px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .sidebar-menu-item-link {
            display: flex;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            color: $sidebar-menu-color;



            &:hover {
            }

            .sidebar-menu-item-link-text {
                display: flex;
                height: 103%;
                align-items: center;
                font-size: 14px;
                color: #ffffff;
                line-height: 1.143;
            }
        }

        &:hover {
            .submenu {
                opacity: 1;
                left: 55px;
            }
        }
    }

    .submenu {
        transition: left 0.2s ease-in, opacity 0.2s ease-in;
        opacity: 0;
        left: 100%;
        position: absolute;
        top: 0;
        padding-top: 26px;
        right: 0;
        bottom: 0;
        background-color: #1a1d45;
        z-index: 1;
        width: calc(100% - 55px);
        height: 100%;
        overflow-y: auto;
    }

    .submenu-item {
        &.active, &:hover {
            background-color: #171936;
        }

        min-height: 21px;

        .submenu-item-link {
            padding: 14px 20px;
            color: #ffffff;
            display: inline-block;
            width: 100%;
        }
    }
}
