@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Light.woff2') format('woff2'),
        url('Oswald-Light.woff') format('woff'),
        url('Oswald-Light.ttf') format('truetype'),
        url('Oswald-Light.svg#Oswald-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Medium.woff2') format('woff2'),
        url('Oswald-Medium.woff') format('woff'),
        url('Oswald-Medium.ttf') format('truetype'),
        url('Oswald-Medium.svg#Oswald-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-SemiBold.woff2') format('woff2'),
        url('Oswald-SemiBold.woff') format('woff'),
        url('Oswald-SemiBold.ttf') format('truetype'),
        url('Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Bold.woff2') format('woff2'),
        url('Oswald-Bold.woff') format('woff'),
        url('Oswald-Bold.ttf') format('truetype'),
        url('Oswald-Bold.svg#Oswald-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-ExtraLight.woff2') format('woff2'),
        url('Oswald-ExtraLight.woff') format('woff'),
        url('Oswald-ExtraLight.ttf') format('truetype'),
        url('Oswald-ExtraLight.svg#Oswald-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Regular.woff2') format('woff2'),
        url('Oswald-Regular.woff') format('woff'),
        url('Oswald-Regular.ttf') format('truetype'),
        url('Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

