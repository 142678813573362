﻿@import '_mixins.scss';

.hierarchy-list {
    user-select: none;

    .hierarchy-list-item-actions {
        position: relative;
        width: 15px;
        flex-shrink: 0;
        color: $actions-dots;

        .item-menu-container {
            cursor: pointer;
            top: calc(50% - 9px);
            position: absolute;
            font-size: 18px;
        }
    }


    .hierarchy-list-item {
        line-height: 1.2;

        & > .hierarchy-list {
            padding-left: 20px;
        }

        &.has-children {
            & > .hierarchy-list-item-inner {
                cursor: pointer;

                &:before {
                    display: flex;
                }
            }
        }

        &.is-open {
            & > .hierarchy-list-item-inner {
                &:before {
                    transform: rotate(0);
                }
            }

            & > .hierarchy-list {
                display: block;
            }
        }

        & > .hierarchy-list {
            display: none;
        }
    }

    .hierarchy-list-item-inner {
        position: relative;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        border-width: 1px;
        border-color: $hierarchy-border-grey;
        border-style: solid;
        border-radius: 5px;
        padding-left: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 10px;
        background-color: $hierarchy-grey;
        margin-bottom: 10px;

        &:before {
            content: '';
            display: none;
            position: absolute;
            width: 10px;
            height: 10px;
            left: 10px;
            top: calc(50% - 5px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='6px'%3E%3Cpath fill-rule='evenodd' fill='rgb(117, 123, 149)' d='M0.500,0.500 L10.003,0.500 L4.573,5.930 L0.500,0.500 Z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(-90deg);
        }

        .hierarchy-list-item-title {
            flex-grow: 1;
        }

        .hierarchy-list-item-status,
        .hierarchy-list-item-date {
            font-size: 13px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            width: 180px;
            padding: 0 10px;
        }

        .hierarchy-list-item-identification {
            font-size: 13px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            width: 130px;
            padding: 0 10px;
        }
    }
}