@import 'mixins';
.btn {
    display: inline-flex;
    width: 100%;
    height: 48px;
    align-items: center;
    font-size: 16px;
    font-family: Raleway, sans-serif;
    border-radius: 10px;
    outline: none;
    border: none;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s background-color linear;
    text-decoration: none;
    text-decoration-color: initial;
    color: inherit;

    &:hover {
        text-decoration: none;
    }

    &[disabled],
    &[disabled="true"] {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.btn-type-2 {
        font-size: 14px;
        min-width: 83px;
        padding: 5px;
        height: 40px;
        @include border-radius-1;
    }

    &.btn-type-3 {
        font-size: 14px;
        font-family: Raleway, sans-serif;
        font-weight: 200;
        justify-content: flex-start;
        width: auto;
        padding: 13px;
        border-radius: 0;
    }

    &.btn-type-4 {
        font-size: 14px;
        width: auto;
        padding: 20px;
        height: 40px;
        @include border-radius-1;
    }

    &.btn-smaller {
        height: 28px;
        width: 225px;
        padding: 5px 10px;
        border-color: $main-text-color;
        color: $main-text-color;
    }

    &.btn-inline {
        display: inline-flex;
        width: auto;
        padding: 5px 15px;
    }

    &.btn-neutral {
        background-color: $exam-result-blue;
        color: #fff;

        &:hover {
            background-color: $exam-result-blue_darker;
        }
    }

    &.btn-neutral-vote {
        border: 1px solid $exam-result-blue_darker;
    }

    &.btn-neutral-vote.active {
        background-color: $exam-result-blue_darker;
        color: #fff;
    }

    &.btn-neutral-vote:hover {
        color: $main_white;
        background-color: $exam-result-blue_darker;
    }

    &.btn-upload {
        font-size: 12px;
        width: auto;
        height: auto;
        padding: 5px 20px;
        background-color: #e0e0e4;
        color: $main-text-color;
        border: 1px solid $main-text-color;
        border-radius: 5px;

        &:hover {
            background-color: $main-text-color-darker;
        }
    }
}

.btn-default-vote {
    border: 1px solid $text-grey-light;

    &:hover {
        color: #fff;
        background-color: $text-grey-light;
    }

    &.active {
        color: #fff;
        background-color: $text-grey-light;
    }
}

.btn-width-200 {
    width: 200px;
}

.btn-width-210 {
    width: 210px;
}


.btn-danger {
    color: $main_white;
    background-color: $main-red;
    border: none;
    &:hover {
        background-color: $main-red_hover;
    }
}

.btn-danger-vote {
    border: 1px solid $main-red;
}

.btn-danger-vote.active, .btn-danger-vote:hover {
    color: $main_white;
    background-color: $main-red;
    border: 1px solid $main-red;
}

.btn-danger-outline {
    color: $main-text-color;
    background-color: transparent;
    border: 1px solid $main-red;

    &:hover {
        background-color: $main-red_hover;
        color: #fff;
    }

    &.smaller {
        font-size: 14px;
        font-family: "Raleway";
        color: $main-text-color;
        line-height: 1.143;
        padding: 5px 15px;
        width: auto;
        min-height:auto;
        height:auto;
        border-radius: 5px;
        &:hover {
            color: #fff;
        }
    }
}

.btn-default {
    background-color: transparent;

    &:hover {
        color: #fff;
        background-color: $text-grey-light;
    }

    &.btn-type-2 {
        @include border-type-1;
    }

    &.btn-type-4 {
        @include border-type-1;
    }
}

.btn-link {
    color: #fff;

    &:hover {
        background-color: $exam-result-blue_darker;
    }

    background-color: $exam-result-blue;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='21px' height='21.3px' viewBox='0 0 21 21.3' style='enable-background:new 0 0 21 21.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' width='21' height='21.3'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cpath class='st0' d='M20.7,21.3H0.3C0.1,21.3,0,21.1,0,21V10.7c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3v10h19.8v-10 c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3V21C21,21.1,20.9,21.3,20.7,21.3'/%3E%3Cpath class='st0' d='M10.5,16.3c-0.2,0-0.3-0.1-0.3-0.3V0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3V16 C10.8,16.2,10.7,16.3,10.5,16.3'/%3E%3Cpath class='st0' d='M10.5,16.8L10.5,16.8c-0.1,0-0.2,0-0.2-0.1l-2.2-3C8,13.5,8,13.3,8.2,13.2c0.1-0.1,0.3-0.1,0.4,0.1l1.9,2.7 l1.9-2.7c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.2,0.3,0.1,0.4l-2.2,3C10.7,16.7,10.6,16.8,10.5,16.8'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 21px;
    background-position: center right 13px;
    padding-right: 60px !important;

}

.buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .btn:not(:last-child) {
        margin-right: 15px;
    }
}

.buttons-container-padding {
    padding: 15px;
}
.buttons-container-bg {
    background-color: #f6f6f6;
}

.input-control-button {
    cursor: pointer;
}

.collapse-siblings {
    margin-right: 8px;
    cursor: pointer;

    &:before {
        content: '-';
        display: block;
    }

    &.collapsed {
        &:before {
            content: '+';
        }
    }

    padding: 0;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 12px;
    height: 12px;
    border: 1px solid $main-text-color;
}